import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../auth/services/auth.service';
import { AuthStatus } from '../../auth/interfaces/authStatus.enum';
import { isPlatformServer } from '@angular/common';

export const isLoggedGuard: CanActivateFn = (route, state) => {
  const platformId = inject(PLATFORM_ID)
  if (isPlatformServer(platformId)) {
    return true;
  }
  const authService = inject(AuthService);
  const router = inject(Router);
  localStorage.setItem('lastUrl', state.url);
  authService.refreshToken();
  if (authService.authStatus() === AuthStatus.AUTHENTICATED) {
    return true;
  }
  if (authService.authStatus() === AuthStatus.CHECKING) {
    return false;
  }
  router.navigateByUrl('/auth/login');
  return false;
};
